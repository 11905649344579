import $ from 'jquery';
//import 'bootstrap';
import * as bootstrap from 'bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment'; // Use moment adapter instead of luxon
import Zoom from 'chartjs-plugin-zoom';
Chart.register(Zoom);
import './style.css';
import 'bootstrap-icons/font/bootstrap-icons.css';


// Fetch hashrate data
function fetchHashrate() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'hashrate', days: 30 },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching hashrate data:', error);
        },
    });
}

// Fetch transactions data
function fetchTransactions() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'transactions', days: 30 },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching transactions data:', error);
        },
    });
}

// Fetch emission data (from block 0)
function fetchEmission() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'emission' },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching emission data:', error);
        },
    });
}


// Fetch circulating supply data
function fetchCirculatingSupply() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'circulating_supply' },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching circulating supply data:', error);
        },
    });
}

// Function to add zoom/pan/reset buttons below each chart
function addChartControls(chartInstance, chartControlsId) {
    const controlsHTML = `
        <div class="d-flex justify-content-start align-items-center mt-2">
            <button class="btn btn-sm btn-outline-primary me-2 toggle-zoom">
                <i class="bi bi-zoom-in"></i> Enable Zoom
            </button>
            <button class="btn btn-sm btn-outline-success me-2 toggle-pan">
                <i class="bi bi-arrows-move"></i> Enable Pan
            </button>
            <button class="btn btn-sm btn-outline-danger reset-zoom">
                <i class="bi bi-arrow-counterclockwise"></i> Reset Zoom
            </button>
        </div>
    `;
    $(`#${chartControlsId}`).html(controlsHTML);

    // Initial state (zoom and pan disabled by default)
    let isZoomEnabled = false;
    let isPanEnabled = true;

    // Toggle Zoom Button
    $(`#${chartControlsId} .toggle-zoom`).click(function() {
        isZoomEnabled = !isZoomEnabled;
        chartInstance.options.plugins.zoom.zoom.wheel.enabled = isZoomEnabled;
        chartInstance.options.plugins.zoom.zoom.pinch.enabled = isZoomEnabled; // Pinch zoom for mobile
        chartInstance.update();
        $(this).html(isZoomEnabled
            ? `<i class="bi bi-zoom-out"></i> Disable Zoom`
            : `<i class="bi bi-zoom-in"></i> Enable Zoom`);
    });

    // Toggle Pan Button
    $(`#${chartControlsId} .toggle-pan`).click(function() {
        isPanEnabled = !isPanEnabled;
        chartInstance.options.plugins.zoom.pan.enabled = isPanEnabled;
        chartInstance.options.plugins.zoom.pan.mode = 'x'; // Set pan mode on x-axis
        chartInstance.update();
        $(this).html(isPanEnabled
            ? `<i class="bi bi-arrows-move"></i> Disable Pan`
            : `<i class="bi bi-arrows-move"></i> Enable Pan`);
    });

    // Reset Zoom Button
    $(`#${chartControlsId} .reset-zoom`).click(function() {
        if (typeof chartInstance.resetZoom === 'function') {
            chartInstance.resetZoom();  // Preferred method if available
        } else {
            // Manual reset fallback if resetZoom doesn't exist
            chartInstance.options.scales.x.min = undefined;
            chartInstance.options.scales.x.max = undefined;
            chartInstance.options.scales.y.min = undefined;
            chartInstance.options.scales.y.max = undefined;
            chartInstance.update();
        }
    });
}



// Function to dynamically create the network charts
function createNetworkCharts(hashrateData, transactionsData, emissionData, circulatingSupplyData) {
    // Clear the current content and add canvases for the charts
    $('#content').html(`
        <div id="chartContainer" class="row">
            <div class="col-12">
                <canvas id="hashrateChart"></canvas>
                <div id="hashrateControls"></div>
            </div>
            <div class="col-12 mt-4">
                <canvas id="transactionsChart"></canvas>
                <div id="transactionsControls"></div>
            </div>
            <div class="col-12 mt-4">
                <canvas id="emissionChart"></canvas>
                <div id="emissionControls"></div>
            </div>
            <div class="col-12 mt-4">
                <canvas id="circulatingSupplyChart"></canvas>
                <div id="circulatingSupplyControls"></div>
            </div>
        </div>
    `);

const zoomOptions = {
    pan: {
        enabled: true, // Disabled by default
        mode: 'x', // Allow panning on the x-axis
    },
    zoom: {
        wheel: {
            enabled: false, // Disabled by default
        },
        drag: {
            enabled: false, // Disabled by default
        },
        pinch: {
            enabled: true, // Disabled by default
        },
        mode: 'x', // Allow zooming on the x-axis
    },
};


    // Hashrate Chart
    const hashrateCtx = document.getElementById('hashrateChart').getContext('2d');
    const hashrateChart = new Chart(hashrateCtx, {
        type: 'line',
        data: {
            labels: hashrateData.labels,
            datasets: [{
                label: 'Hashrate (MH)',
                data: hashrateData.hashrate.map(h => h / 100),
                borderColor: 'rgba(75, 192, 192, 1)',
                fill: false,
            }],
        },
        options: {
            scales: {
                x: { type: 'time', time: { parser: 'YYYY-MM-DD HH:mm:ss', unit: 'day' } },
                y: { title: { display: true, text: 'MH/s' } },
            },
            plugins: {
                zoom: zoomOptions,
            },
        },
    });

    // Add controls for hashrate chart
    addChartControls(hashrateChart, 'hashrateControls');

    // Transactions Chart
    const transactionsCtx = document.getElementById('transactionsChart').getContext('2d');
    const transactionsChart = new Chart(transactionsCtx, {
        type: 'line',
        data: {
            labels: transactionsData.labels,
            datasets: [{
                label: 'Transactions',
                data: transactionsData.transactions,
                borderColor: 'rgba(255, 99, 132, 1)',
                fill: false,
            }],
        },
        options: {
            scales: {
                x: { type: 'time', time: { parser: 'YYYY-MM-DD HH:mm:ss', unit: 'day' } },
                y: { title: { display: true, text: 'Count' } },
            },
            plugins: {
                zoom: zoomOptions,
            },
        },
    });

    // Add controls for transactions chart
    addChartControls(transactionsChart, 'transactionsControls');


    // Emission Chart
    const emissionCtx = document.getElementById('emissionChart').getContext('2d');
    const emissionChart = new Chart(emissionCtx, {
        type: 'line',
        data: {
            labels: emissionData.labels,
            datasets: [{
                label: 'Cumulative Emission (SAL)',
                data: emissionData.emission.map(e => e / 0.1),
                backgroundColor: 'rgba(153, 102, 255, 0.5)',
                borderColor: 'rgba(153, 102, 255, 1)',
                fill: false,
            }],
        },
        options: {
            scales: {
                x: { type: 'time', time: { parser: 'YYYY-MM-DD HH:mm:ss', unit: 'day' } },
                y: { title: { display: true, text: 'SAL' } },
            },
            plugins: {
                zoom: zoomOptions,
            },
        },
    });

    // Add controls for emission chart
    addChartControls(emissionChart, 'emissionControls');


    // Circulating Supply Chart
    const circulatingSupplyCtx = document.getElementById('circulatingSupplyChart').getContext('2d');
    const circulatingSupplyChart = new Chart(circulatingSupplyCtx, {
        type: 'line',
        data: {
            labels: circulatingSupplyData.labels,
            datasets: [{
                label: 'Circulating Unlocked Supply (SAL)',
                data: circulatingSupplyData.circulating.map(e => e / 0.1),
                borderColor: 'rgba(54, 162, 235, 1)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                fill: true,
            }],
        },
        options: {
            scales: {
                x: { type: 'time', time: { parser: 'YYYY-MM-DD HH:mm:ss', unit: 'day' } },
                y: { title: { display: true, text: 'SAL' } },
            },
            plugins: {
                zoom: zoomOptions,
            },
        },
    });

    // Add controls for circulating supply chart
    addChartControls(circulatingSupplyChart, 'circulatingSupplyControls');

}


// Event handler for tab navigation
function setupTabNavigation() {
    $('#networkLink').click(async function (e) {
        e.preventDefault();
        // Set the active tab
        $('.nav-link').removeClass('active');
        $(this).addClass('active');

        // Fetch all data in parallel
        const [hashrateData, transactionsData, emissionData, circulatingSupplyData] = await Promise.all([
            fetchHashrate(),
            fetchTransactions(),
            fetchEmission(),
            fetchCirculatingSupply()
        ]);

        // Create the network charts
        createNetworkCharts(hashrateData, transactionsData, emissionData, circulatingSupplyData);
    });

    // Other tab handlers (Staking, Price) remain unchanged
}


// Fetch staked coins data
function fetchStakedCoins() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'staked_coins', days: 30 },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching staked coins data:', error);
        },
    });
}

// Fetch new stakes data
function fetchNewStakes() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'new_stakes', days: 30 },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching new stakes data:', error);
        },
    });
}

// Fetch yield data
function fetchYield() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'yield', days: 30 },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching yield data:', error);
        },
    });
}

// Function to create the staking charts
function createStakedCoinsChart(labels, staked_coins) {
    const adjustedStakedCoins = staked_coins.map(c => c * 10); // Multiply by 10
    const ctx = document.getElementById('stakedCoinsChart').getContext('2d');
    new Chart(ctx, {
        type: 'line',
        data: {
            labels: labels,
            datasets: [{
                label: 'Staked Coins',
                data: adjustedStakedCoins,
                borderColor: 'rgba(75, 192, 192, 1)',
                fill: false,
            }],
        },
        options: {
            scales: {
                x: { type: 'time', time: { unit: 'day' } },
                y: { title: { display: true, text: 'SAL' } },
            },
        },
    });
}

function createNewStakesChart(labels, new_stakes) {
    const adjustedNewStakes = new_stakes.map(s => s * 10); // Multiply by 10
    const ctx = document.getElementById('newStakesChart').getContext('2d');
    new Chart(ctx, {
        type: 'bar',
        data: {
            labels: labels,
            datasets: [{
                label: 'New Stakes',
                data: adjustedNewStakes,
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
            }],
        },
        options: {
            scales: {
                x: { type: 'time', time: { unit: 'day' } },
                y: { title: { display: true, text: 'SAL' } },
            },
        },
    });
}


function createYieldChart(labels, yieldData) {
    const ctx = document.getElementById('yieldChart').getContext('2d');
    new Chart(ctx, {
        type: 'line',
        data: {
            labels: labels,
            datasets: [{
                label: 'Yield (%)',
                data: yieldData,
                borderColor: 'rgba(255, 99, 132, 1)',
                fill: false,
            }],
        },
        options: {
            scales: {
                x: { type: 'time', time: { unit: 'day' } },
                y: { title: { display: true, text: '%' } },
            },
        },
    });
}


// Fetch top 10 stakes
function fetchTopStakes() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'top_stakes' },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching top stakes data:', error);
        },
    });
}

// Fetch recently unlocked stakes
function fetchRecentlyUnlocked() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'recently_unlocked' },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching recently unlocked stakes:', error);
        },
    });
}

// Create Top Stakes Table
function createTopStakesTable(data) {
    let tableHtml = `
        <h3>Top 10 Stakes</h3>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Staked Amount (SAL)</th>
                    <th>Unlocked Amount (SAL)</th>
                    <th>Yield (%)</th>
                    <th>Locked at Height</th>
                    <th>Unlocked at Height</th>
                    <th>Stake Hash</th>
                </tr>
            </thead>
            <tbody>
    `;

    data.forEach(row => {
        tableHtml += `
            <tr>
                <td>${(row.staked_amount / 1e9).toFixed(2)}</td>
                <td>${(row.unlocked_amount / 1e9).toFixed(2)}</td>
                <td>${row.yield.toFixed(2)}</td>
                <td>${row.block_height_locked}</td>
                <td>${row.block_height_unlocked}</td>
                <td>${row.stake_tx_hash}</td>
            </tr>
        `;
    });

    tableHtml += '</tbody></table>';
    $('#topStakesTable').html(tableHtml);
}

// Create Recently Unlocked Stakes Table
function createRecentlyUnlockedTable(data) {
    let tableHtml = `
        <h3>Recently Unlocked Stakes</h3>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Staked Amount (SAL)</th>
                    <th>Unlocked Amount (SAL)</th>
                    <th>Yield (%)</th>
                    <th>Locked at Height</th>
                    <th>Unlocked at Height</th>
                    <th>Stake Hash</th>
                </tr>
            </thead>
            <tbody>
    `;

    data.forEach(row => {
        tableHtml += `
            <tr>
                <td>${(row.staked_amount / 1e9).toFixed(2)}</td>
                <td>${(row.unlocked_amount / 1e9).toFixed(2)}</td>
                <td>${row.yield.toFixed(2)}</td>
                <td>${row.block_height_locked}</td>
                <td>${row.block_height_unlocked}</td>
                <td>${row.stake_tx_hash}</td>
            </tr>
        `;
    });

    tableHtml += '</tbody></table>';
    $('#recentlyUnlockedTable').html(tableHtml);
}


function setupStakingTab() {
    $('#stakingLink').click(async function (e) {
        e.preventDefault();
        $('.nav-link').removeClass('active');
        $(this).addClass('active');

        // Clear current content and add canvases for staking charts
        $('#content').html(`
            <div id="stakingChartsContainer" class="row">
                <div class="col-12">
                    <canvas id="stakedCoinsChart"></canvas>
                </div>
                <div class="col-12 mt-4">
                    <canvas id="newStakesChart"></canvas>
                </div>
                <div class="col-12 mt-4">
                    <canvas id="yieldChart"></canvas>
                </div>
            </div>
            <hr>
            <div id="topStakesTable"></div>
            <div id="recentlyUnlockedTable"></div>
        `);

        // Fetch and render charts
        try {
            const stakedCoinsData = await fetchStakedCoins();
            createStakedCoinsChart(stakedCoinsData.labels, stakedCoinsData.staked_coins);

            const newStakesData = await fetchNewStakes();
            createNewStakesChart(newStakesData.labels, newStakesData.new_stakes);

            const yieldData = await fetchYield();
            createYieldChart(yieldData.labels, yieldData.yield);

                // Fetch and render the tables
    const topStakesData = await fetchTopStakes();
    createTopStakesTable(topStakesData);

    const recentlyUnlockedData = await fetchRecentlyUnlocked();
    createRecentlyUnlockedTable(recentlyUnlockedData);


        } catch (error) {
            console.error('Error loading staking charts:', error);
        }
    });
}

// Global variable for the current limit (rows per page)
let currentLimit = 50;

// Fetch transactions data for charts
function fetchAuditChart() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'audit_chart', days: 30 },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching audit tx data:', error);
        },
    });
}

// Function to compute cumulative sum
function computeCumulativeAmounts(amounts) {
    let cumulativeSum = 0;
    return amounts.map(amount => {
        cumulativeSum += amount;
        return cumulativeSum;
    });
}

// Function to create the standard audited coins chart
function createAuditedCoinsChart(labels, amounts) {
    const adjustedAuditedCoins = amounts.map(c => c / 100000000);
    const ctx = document.getElementById('auditedCoinsChart').getContext('2d');
    new Chart(ctx, {
        type: 'line',
        data: {
            labels: labels,
            datasets: [{
                label: 'Audited Coins',
                data: adjustedAuditedCoins,
                borderColor: 'rgba(75, 192, 192, 1)',
                fill: false,
            }],
        },
        options: {
            scales: {
                x: { type: 'time', time: { unit: 'day' } },
                y: { title: { display: true, text: 'SAL' } },
            },
        },
    });
}

// Function to create the cumulative audited coins chart
function createCumulativeAuditChart(labels, amounts) {
    const cumulativeAmounts = computeCumulativeAmounts(amounts).map(c => c / 100000000);
    const ctx = document.getElementById('cumulativeAuditChart').getContext('2d');
    new Chart(ctx, {
        type: 'line',
        data: {
            labels: labels,
            datasets: [{
                label: 'Cumulative Audited Coins',
                data: cumulativeAmounts,
                borderColor: 'rgba(255, 99, 132, 1)',
                fill: false,
            }],
        },
        options: {
            scales: {
                x: { type: 'time', time: { unit: 'day' } },
                y: { title: { display: true, text: 'Cumulative SAL' } },
            },
        },
    });
}

// Fetch audit transactions data with pagination
function fetchAuditTransactions(page = 1, limit = currentLimit) {
    const offset = (page - 1) * limit;
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'audit_transactions', limit, offset },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching audit transactions:', error);
        },
    });
}

/* --- Helper: Initialize/Reinitialize Popovers --- */
function initializePopovers() {
    // Initialize popovers with manual trigger and bottom placement
    $('.input-popover').each(function() {
        new bootstrap.Popover(this, { trigger: 'manual', placement: 'bottom' });
    });
    // Toggle popover on click using aria-describedby to determine open state
    $('.input-popover').off('click').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        let popover = bootstrap.Popover.getInstance(this);
        if ($(this).attr('aria-describedby')) {
            popover.hide();
        } else {
            // Hide any other open popovers first
            $('.input-popover').each(function() {
                let inst = bootstrap.Popover.getInstance(this);
                if (inst) { inst.hide(); }
            });
            popover.show();
        }
    });
    // Hide popovers when clicking outside
    $(document).off('click.popover').on('click.popover', function(e) {
        if (!$(e.target).closest('.popover').length && !$(e.target).closest('.input-popover').length) {
            $('.input-popover').each(function() {
                let inst = bootstrap.Popover.getInstance(this);
                if (inst) { inst.hide(); }
            });
        }
    });
}

// Function to create the audit transactions table
function createAuditTransactionsTable(data, page = 1, limit = currentLimit) {
    let tableHtml = `
        <h3>Audit Transactions</h3>
        <table class="table table-striped sortable-table">
            <thead>
                <tr>
                    <!-- Each header has a data-order attribute and a default up-arrow -->
                    <th class="sortable" data-sort="height" data-order="asc">
                        Height <i class="bi bi-arrow-up sort-arrow"></i>
                    </th>
                    <th class="sortable" data-sort="time" data-order="asc">
                        Time <i class="bi bi-arrow-up sort-arrow"></i>
                    </th>
                    <th>Transaction Hash</th>
                    <th class="sortable" data-sort="amount" data-order="asc">
                        Amount (SAL) <i class="bi bi-arrow-up sort-arrow"></i>
                    </th>
                    <th class="sortable" data-sort="inputs" data-order="asc">
                        Inputs <i class="bi bi-arrow-up sort-arrow"></i>
                    </th>
                </tr>
            </thead>
            <tbody>
    `;

    data.transactions.forEach(row => {
        tableHtml += `
            <tr>
                <td>${row.height}</td>
                <td>${row.time}</td>
                <td>
                    <a href="https://explorer.salvium.io/tx/${row.tx_hash}" target="_blank">
                        ${row.tx_hash.substring(0, 12)}...
                    </a>
                </td>
                <td>${(row.amount / 1e8).toFixed(2)}</td>
                <td>
                    <a href="#" class="input-popover" data-bs-toggle="popover" data-bs-content="${row.inputs}">
                        ${row.input_count} Inputs
                    </a>
                </td>
            </tr>
        `;
    });

    tableHtml += '</tbody></table>';

    // Add pagination controls along with a "rows per page" selector
    tableHtml += `
        <nav>
            <ul class="pagination justify-content-center">
                <li class="page-item ${page === 1 ? 'disabled' : ''}">
                    <a class="page-link" href="#" data-page="${page - 1}">Previous</a>
                </li>
                <li class="page-item">
                    <a class="page-link" href="#" data-page="${page + 1}">Next</a>
                </li>
            </ul>
        </nav>
        <div class="d-flex justify-content-center align-items-center mt-2 mb-3">
            <span>Rows per page:&nbsp;</span>
            <select id="rowsPerPage" class="form-select form-select-sm ms-2" style="width: auto;">
                <option value="50" ${limit == 50 ? "selected" : ""}>50</option>
                <option value="100" ${limit == 100 ? "selected" : ""}>100</option>
                <option value="200" ${limit == 200 ? "selected" : ""}>200</option>
                <option value="500" ${limit == 500 ? "selected" : ""}>500</option>
            </select>
        </div>
    `;

    // Render the table into the container
    $('#auditTransactionsTable').html(tableHtml);

    // Reinitialize popovers on new table elements
    initializePopovers();

    /* --- Set up sorting with arrow toggling --- */
    $('.sortable').off('click').on('click', function () {
        let $header = $(this);
        let sortType = $header.data('sort');
        // Toggle order
        let currentOrder = $header.data('order') || 'asc';
        let newOrder = currentOrder === 'asc' ? 'desc' : 'asc';
        $header.data('order', newOrder);
        // Update arrow icon
        $header.find('i.sort-arrow').attr('class', newOrder === 'asc'
             ? 'bi bi-arrow-up sort-arrow'
             : 'bi bi-arrow-down sort-arrow');

        let headerIndex = $header.index();
        let rows = $('tbody tr').get();
        rows.sort(function (a, b) {
            let cellA = $(a).children('td').eq(headerIndex).text().trim();
            let cellB = $(b).children('td').eq(headerIndex).text().trim();
            let cmp;
            if (sortType === 'time') {
                // Parse as dates for time-sorting
                let timeA = Date.parse(cellA);
                let timeB = Date.parse(cellB);
                cmp = timeA - timeB;
            } else {
                let numA = parseFloat(cellA.replace(/,/g, ''));
                let numB = parseFloat(cellB.replace(/,/g, ''));
                cmp = (!isNaN(numA) && !isNaN(numB))
                      ? numA - numB
                      : cellA.localeCompare(cellB);
            }
            return newOrder === 'asc' ? cmp : -cmp;
        });
        $('tbody').empty().append(rows);
        // Reinitialize popovers after sorting
        initializePopovers();
    });

    // Bind event for rows-per-page selection (rebind every time the table is rendered)
    $('#rowsPerPage').off('change').on('change', function() {
        currentLimit = parseInt($(this).val());
        // Reload page 1 with the new limit
        fetchAuditTransactions(1, currentLimit).then(newData => createAuditTransactionsTable(newData, 1, currentLimit));
    });
}

/* --- Delegated event binding for pagination --- */
$(document).off('click', '.pagination .page-link').on('click', '.pagination .page-link', function(e) {
    e.preventDefault();
    const newPage = $(this).data('page');
    if (newPage > 0) {
         fetchAuditTransactions(newPage, currentLimit).then(newData => createAuditTransactionsTable(newData, newPage, currentLimit));
    }
});

// Initialize audit transactions tab
function setupAuditTab() {
    $('#auditLink').click(async function (e) {
        e.preventDefault();
        $('.nav-link').removeClass('active');
        $(this).addClass('active');

        // Insert chart canvases and transactions table container
        $('#content').html(`
            <div id="auditChartsContainer" class="row">
                <div class="col-12"><canvas id="auditedCoinsChart"></canvas></div>
                <div class="col-12"><canvas id="cumulativeAuditChart"></canvas></div>
            </div>
            <hr>
            <div id="auditTransactionsTable"></div>
        `);

        try {
            // Fetch and render charts
            const auditedCoinsData = await fetchAuditChart();
            createAuditedCoinsChart(auditedCoinsData.labels, auditedCoinsData.amounts);
            createCumulativeAuditChart(auditedCoinsData.labels, auditedCoinsData.amounts);

            // Fetch and render transactions table (page 1)
            const auditTxData = await fetchAuditTransactions();
            createAuditTransactionsTable(auditTxData);
        } catch (error) {
            console.error('Error loading audit charts and transactions:', error);
        }
    });
}



/*
function setupAuditTab() {
    $('#auditLink').click(async function (e) {
        e.preventDefault();
        $('.nav-link').removeClass('active');
        $(this).addClass('active');

        // Clear current content and add canvases for staking charts
        $('#content').html(`
            <div id="auditChartsContainer" class="row">
                <div class="col-12">
                    <canvas id="auditedCoinsChart"></canvas>
                </div>
                <div class="col-12">
                    <canvas id="cumulativeAuditChart"></canvas>
                </div>
            </div>
            <hr>
            <div id="auditTransactionssTable"></div>
        `);

        // Fetch and render charts
        try {
            const auditedCoinsData = await fetchAuditChart();
            createAuditedCoinsChart(auditedCoinsData.labels, auditedCoinsData.amounts);
            createCumulativeAuditChart(auditedCoinsData.labels, auditedCoinsData.amounts);
        } catch (error) {
            console.error('Error loading audit charts:', error);
        }
    });
}
*/


// Initialize the app
$(document).ready(async function () {
    // Set the default active tab
    $('#networkLink').addClass('active');
    setupTabNavigation();

    // Trigger the network charts on load
    $('#networkLink').trigger('click');


    setupStakingTab();

    setupAuditTab();
});

  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="//stats.salvium.pl/matomo/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '1']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();
