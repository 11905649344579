import $ from 'jquery';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment'; // Use moment adapter instead of luxon
import Zoom from 'chartjs-plugin-zoom';
Chart.register(Zoom);
import './style.css';
import 'bootstrap-icons/font/bootstrap-icons.css';


// Fetch hashrate data
function fetchHashrate() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'hashrate', days: 30 },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching hashrate data:', error);
        },
    });
}

// Fetch transactions data
function fetchTransactions() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'transactions', days: 30 },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching transactions data:', error);
        },
    });
}

// Fetch emission data (from block 0)
function fetchEmission() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'emission' },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching emission data:', error);
        },
    });
}


// Fetch circulating supply data
function fetchCirculatingSupply() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'circulating_supply' },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching circulating supply data:', error);
        },
    });
}

// Function to add zoom/pan/reset buttons below each chart
function addChartControls(chartInstance, chartControlsId) {
    const controlsHTML = `
        <div class="d-flex justify-content-start align-items-center mt-2">
            <button class="btn btn-sm btn-outline-primary me-2 toggle-zoom">
                <i class="bi bi-zoom-in"></i> Enable Zoom
            </button>
            <button class="btn btn-sm btn-outline-success me-2 toggle-pan">
                <i class="bi bi-arrows-move"></i> Enable Pan
            </button>
            <button class="btn btn-sm btn-outline-danger reset-zoom">
                <i class="bi bi-arrow-counterclockwise"></i> Reset Zoom
            </button>
        </div>
    `;
    $(`#${chartControlsId}`).html(controlsHTML);

    // Initial state (zoom and pan disabled by default)
    let isZoomEnabled = false;
    let isPanEnabled = true;

    // Toggle Zoom Button
    $(`#${chartControlsId} .toggle-zoom`).click(function() {
        isZoomEnabled = !isZoomEnabled;
        chartInstance.options.plugins.zoom.zoom.wheel.enabled = isZoomEnabled;
        chartInstance.options.plugins.zoom.zoom.pinch.enabled = isZoomEnabled; // Pinch zoom for mobile
        chartInstance.update();
        $(this).html(isZoomEnabled
            ? `<i class="bi bi-zoom-out"></i> Disable Zoom`
            : `<i class="bi bi-zoom-in"></i> Enable Zoom`);
    });

    // Toggle Pan Button
    $(`#${chartControlsId} .toggle-pan`).click(function() {
        isPanEnabled = !isPanEnabled;
        chartInstance.options.plugins.zoom.pan.enabled = isPanEnabled;
        chartInstance.options.plugins.zoom.pan.mode = 'x'; // Set pan mode on x-axis
        chartInstance.update();
        $(this).html(isPanEnabled
            ? `<i class="bi bi-arrows-move"></i> Disable Pan`
            : `<i class="bi bi-arrows-move"></i> Enable Pan`);
    });

    // Reset Zoom Button
    $(`#${chartControlsId} .reset-zoom`).click(function() {
        if (typeof chartInstance.resetZoom === 'function') {
            chartInstance.resetZoom();  // Preferred method if available
        } else {
            // Manual reset fallback if resetZoom doesn't exist
            chartInstance.options.scales.x.min = undefined;
            chartInstance.options.scales.x.max = undefined;
            chartInstance.options.scales.y.min = undefined;
            chartInstance.options.scales.y.max = undefined;
            chartInstance.update();
        }
    });
}



// Function to dynamically create the network charts
function createNetworkCharts(hashrateData, transactionsData, emissionData, circulatingSupplyData) {
    // Clear the current content and add canvases for the charts
    $('#content').html(`
        <div id="chartContainer" class="row">
            <div class="col-12">
                <canvas id="hashrateChart"></canvas>
                <div id="hashrateControls"></div>
            </div>
            <div class="col-12 mt-4">
                <canvas id="transactionsChart"></canvas>
                <div id="transactionsControls"></div>
            </div>
            <div class="col-12 mt-4">
                <canvas id="emissionChart"></canvas>
                <div id="emissionControls"></div>
            </div>
            <div class="col-12 mt-4">
                <canvas id="circulatingSupplyChart"></canvas>
                <div id="circulatingSupplyControls"></div>
            </div>
        </div>
    `);

const zoomOptions = {
    pan: {
        enabled: true, // Disabled by default
        mode: 'x', // Allow panning on the x-axis
    },
    zoom: {
        wheel: {
            enabled: false, // Disabled by default
        },
        drag: {
            enabled: false, // Disabled by default
        },
        pinch: {
            enabled: true, // Disabled by default
        },
        mode: 'x', // Allow zooming on the x-axis
    },
};


    // Hashrate Chart
    const hashrateCtx = document.getElementById('hashrateChart').getContext('2d');
    const hashrateChart = new Chart(hashrateCtx, {
        type: 'line',
        data: {
            labels: hashrateData.labels,
            datasets: [{
                label: 'Hashrate (MH)',
                data: hashrateData.hashrate.map(h => h / 100),
                borderColor: 'rgba(75, 192, 192, 1)',
                fill: false,
            }],
        },
        options: {
            scales: {
                x: { type: 'time', time: { parser: 'YYYY-MM-DD HH:mm:ss', unit: 'day' } },
                y: { title: { display: true, text: 'MH/s' } },
            },
            plugins: {
                zoom: zoomOptions,
            },
        },
    });

    // Add controls for hashrate chart
    addChartControls(hashrateChart, 'hashrateControls');

    // Transactions Chart
    const transactionsCtx = document.getElementById('transactionsChart').getContext('2d');
    const transactionsChart = new Chart(transactionsCtx, {
        type: 'line',
        data: {
            labels: transactionsData.labels,
            datasets: [{
                label: 'Transactions',
                data: transactionsData.transactions,
                borderColor: 'rgba(255, 99, 132, 1)',
                fill: false,
            }],
        },
        options: {
            scales: {
                x: { type: 'time', time: { parser: 'YYYY-MM-DD HH:mm:ss', unit: 'day' } },
                y: { title: { display: true, text: 'Count' } },
            },
            plugins: {
                zoom: zoomOptions,
            },
        },
    });

    // Add controls for transactions chart
    addChartControls(transactionsChart, 'transactionsControls');


    // Emission Chart
    const emissionCtx = document.getElementById('emissionChart').getContext('2d');
    const emissionChart = new Chart(emissionCtx, {
        type: 'line',
        data: {
            labels: emissionData.labels,
            datasets: [{
                label: 'Cumulative Emission (SAL)',
                data: emissionData.emission.map(e => e / 0.1),
                backgroundColor: 'rgba(153, 102, 255, 0.5)',
                borderColor: 'rgba(153, 102, 255, 1)',
                fill: false,
            }],
        },
        options: {
            scales: {
                x: { type: 'time', time: { parser: 'YYYY-MM-DD HH:mm:ss', unit: 'day' } },
                y: { title: { display: true, text: 'SAL' } },
            },
            plugins: {
                zoom: zoomOptions,
            },
        },
    });

    // Add controls for emission chart
    addChartControls(emissionChart, 'emissionControls');


    // Circulating Supply Chart
    const circulatingSupplyCtx = document.getElementById('circulatingSupplyChart').getContext('2d');
    const circulatingSupplyChart = new Chart(circulatingSupplyCtx, {
        type: 'line',
        data: {
            labels: circulatingSupplyData.labels,
            datasets: [{
                label: 'Circulating Unlocked Supply (SAL)',
                data: circulatingSupplyData.circulating.map(e => e / 0.1),
                borderColor: 'rgba(54, 162, 235, 1)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                fill: true,
            }],
        },
        options: {
            scales: {
                x: { type: 'time', time: { parser: 'YYYY-MM-DD HH:mm:ss', unit: 'day' } },
                y: { title: { display: true, text: 'SAL' } },
            },
            plugins: {
                zoom: zoomOptions,
            },
        },
    });

    // Add controls for circulating supply chart
    addChartControls(circulatingSupplyChart, 'circulatingSupplyControls');

}


// Event handler for tab navigation
function setupTabNavigation() {
    $('#networkLink').click(async function (e) {
        e.preventDefault();
        // Set the active tab
        $('.nav-link').removeClass('active');
        $(this).addClass('active');

        // Fetch all data in parallel
        const [hashrateData, transactionsData, emissionData, circulatingSupplyData] = await Promise.all([
            fetchHashrate(),
            fetchTransactions(),
            fetchEmission(),
            fetchCirculatingSupply()
        ]);

        // Create the network charts
        createNetworkCharts(hashrateData, transactionsData, emissionData, circulatingSupplyData);
    });

    // Other tab handlers (Staking, Price) remain unchanged
}


// Fetch staked coins data
function fetchStakedCoins() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'staked_coins', days: 30 },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching staked coins data:', error);
        },
    });
}

// Fetch new stakes data
function fetchNewStakes() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'new_stakes', days: 30 },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching new stakes data:', error);
        },
    });
}

// Fetch yield data
function fetchYield() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'yield', days: 30 },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching yield data:', error);
        },
    });
}

// Function to create the staking charts
function createStakedCoinsChart(labels, staked_coins) {
    const adjustedStakedCoins = staked_coins.map(c => c * 10); // Multiply by 10
    const ctx = document.getElementById('stakedCoinsChart').getContext('2d');
    new Chart(ctx, {
        type: 'line',
        data: {
            labels: labels,
            datasets: [{
                label: 'Staked Coins',
                data: adjustedStakedCoins,
                borderColor: 'rgba(75, 192, 192, 1)',
                fill: false,
            }],
        },
        options: {
            scales: {
                x: { type: 'time', time: { unit: 'day' } },
                y: { title: { display: true, text: 'SAL' } },
            },
        },
    });
}

function createNewStakesChart(labels, new_stakes) {
    const adjustedNewStakes = new_stakes.map(s => s * 10); // Multiply by 10
    const ctx = document.getElementById('newStakesChart').getContext('2d');
    new Chart(ctx, {
        type: 'bar',
        data: {
            labels: labels,
            datasets: [{
                label: 'New Stakes',
                data: adjustedNewStakes,
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
            }],
        },
        options: {
            scales: {
                x: { type: 'time', time: { unit: 'day' } },
                y: { title: { display: true, text: 'SAL' } },
            },
        },
    });
}


function createYieldChart(labels, yieldData) {
    const ctx = document.getElementById('yieldChart').getContext('2d');
    new Chart(ctx, {
        type: 'line',
        data: {
            labels: labels,
            datasets: [{
                label: 'Yield (%)',
                data: yieldData,
                borderColor: 'rgba(255, 99, 132, 1)',
                fill: false,
            }],
        },
        options: {
            scales: {
                x: { type: 'time', time: { unit: 'day' } },
                y: { title: { display: true, text: '%' } },
            },
        },
    });
}


// Fetch top 10 stakes
function fetchTopStakes() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'top_stakes' },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching top stakes data:', error);
        },
    });
}

// Fetch recently unlocked stakes
function fetchRecentlyUnlocked() {
    return $.ajax({
        url: 'chain.php',
        dataType: 'json',
        data: { type: 'recently_unlocked' },
        method: 'GET',
        error: function (xhr, status, error) {
            console.error('Error fetching recently unlocked stakes:', error);
        },
    });
}

// Create Top Stakes Table
function createTopStakesTable(data) {
    let tableHtml = `
        <h3>Top 10 Stakes</h3>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Staked Amount (SAL)</th>
                    <th>Unlocked Amount (SAL)</th>
                    <th>Yield (%)</th>
                    <th>Locked at Height</th>
                    <th>Unlocked at Height</th>
                    <th>Stake Hash</th>
                </tr>
            </thead>
            <tbody>
    `;

    data.forEach(row => {
        tableHtml += `
            <tr>
                <td>${(row.staked_amount / 1e9).toFixed(2)}</td>
                <td>${(row.unlocked_amount / 1e9).toFixed(2)}</td>
                <td>${row.yield.toFixed(2)}</td>
                <td>${row.block_height_locked}</td>
                <td>${row.block_height_unlocked}</td>
                <td>${row.stake_tx_hash}</td>
            </tr>
        `;
    });

    tableHtml += '</tbody></table>';
    $('#topStakesTable').html(tableHtml);
}

// Create Recently Unlocked Stakes Table
function createRecentlyUnlockedTable(data) {
    let tableHtml = `
        <h3>Recently Unlocked Stakes</h3>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Staked Amount (SAL)</th>
                    <th>Unlocked Amount (SAL)</th>
                    <th>Yield (%)</th>
                    <th>Locked at Height</th>
                    <th>Unlocked at Height</th>
                    <th>Stake Hash</th>
                </tr>
            </thead>
            <tbody>
    `;

    data.forEach(row => {
        tableHtml += `
            <tr>
                <td>${(row.staked_amount / 1e9).toFixed(2)}</td>
                <td>${(row.unlocked_amount / 1e9).toFixed(2)}</td>
                <td>${row.yield.toFixed(2)}</td>
                <td>${row.block_height_locked}</td>
                <td>${row.block_height_unlocked}</td>
                <td>${row.stake_tx_hash}</td>
            </tr>
        `;
    });

    tableHtml += '</tbody></table>';
    $('#recentlyUnlockedTable').html(tableHtml);
}


function setupStakingTab() {
    $('#stakingLink').click(async function (e) {
        e.preventDefault();
        $('.nav-link').removeClass('active');
        $(this).addClass('active');

        // Clear current content and add canvases for staking charts
        $('#content').html(`
            <div id="stakingChartsContainer" class="row">
                <div class="col-12">
                    <canvas id="stakedCoinsChart"></canvas>
                </div>
                <div class="col-12 mt-4">
                    <canvas id="newStakesChart"></canvas>
                </div>
                <div class="col-12 mt-4">
                    <canvas id="yieldChart"></canvas>
                </div>
            </div>
            <hr>
            <div id="topStakesTable"></div>
            <div id="recentlyUnlockedTable"></div>
        `);

        // Fetch and render charts
        try {
            const stakedCoinsData = await fetchStakedCoins();
            createStakedCoinsChart(stakedCoinsData.labels, stakedCoinsData.staked_coins);

            const newStakesData = await fetchNewStakes();
            createNewStakesChart(newStakesData.labels, newStakesData.new_stakes);

            const yieldData = await fetchYield();
            createYieldChart(yieldData.labels, yieldData.yield);

                // Fetch and render the tables
    const topStakesData = await fetchTopStakes();
    createTopStakesTable(topStakesData);

    const recentlyUnlockedData = await fetchRecentlyUnlocked();
    createRecentlyUnlockedTable(recentlyUnlockedData);


        } catch (error) {
            console.error('Error loading staking charts:', error);
        }
    });
}



// Initialize the app
$(document).ready(async function () {
    // Set the default active tab
    $('#networkLink').addClass('active');
    setupTabNavigation();

    // Trigger the network charts on load
    $('#networkLink').trigger('click');


    setupStakingTab();
});

  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="//stats.salvium.pl/matomo/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '1']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();
